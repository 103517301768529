import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../../src/assets/img/rank/first.png';
import _imports_1 from '../../../src/assets/img/rank/second.png';
import _imports_2 from '../../../src/assets/img/rank/three.png';

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-365877b1"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "detail"
};
var _hoisted_2 = {
  class: "content"
};
var _hoisted_3 = {
  key: 0,
  class: "bottom",
  style: {
    "margin-top": "15px"
  }
};
var _hoisted_4 = {
  key: 0,
  src: _imports_0,
  style: {
    "width": "25px",
    "height": "30px",
    "vertical-align": "middle"
  }
};
var _hoisted_5 = {
  key: 1,
  src: _imports_1,
  style: {
    "width": "25px",
    "height": "30px",
    "vertical-align": "middle"
  }
};
var _hoisted_6 = {
  key: 2,
  src: _imports_2,
  style: {
    "width": "25px",
    "height": "30px",
    "vertical-align": "middle"
  }
};
var _hoisted_7 = {
  key: 3
};
var _hoisted_8 = {
  class: "item-name",
  style: {
    "margin-left": "10px"
  }
};
var _hoisted_9 = {
  key: 1,
  style: {
    "text-align": "center",
    "margin-top": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "排行榜",
    back: true
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_ctx.isscore ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.rank, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "rankbox"
    }, [_createElementVNode("div", null, [index == 0 ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : index == 1 ? (_openBlock(), _createElementBlock("img", _hoisted_5)) : index == 2 ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(index + 1), 1)), _createElementVNode("span", _hoisted_8, _toDisplayString(item.username), 1)]), _createElementVNode("span", null, _toDisplayString(item.score || 0) + "分", 1)]);
  }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_9, " 暂无排行 "))])])], 64);
}